const Watermark = ({ text = "", opacity = 0.1, fontSize = "20px", zIndex = 10000 }) => {
  const watermarkStyles: any = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    pointerEvents: "none",
    zIndex: zIndex,
    backgroundSize: "250px 250px", // Adjust this for repeating watermark
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='400' height='400'><text x='10%' y='90%' dominant-baseline='middle' text-anchor='middle' font-size='${fontSize}' fill='gray' fill-opacity='${opacity}' transform='rotate(-45)'>${text}</text></svg>")`,
  };

  return <div style={watermarkStyles}></div>;
};

const WatermarkWrapper = ({ children, text }: any) => {
  const _text = text?.includes("@") ? text.split("@")[0] : text;
  return (
    <>
      <Watermark text={_text} opacity={0.4} fontSize="30px" />
      {children}
    </>
  );
};

export default WatermarkWrapper;
